import Lottie from 'lottie-react';
import animationData from '../../assets/Loaders/loader_animation.json';

export const AFLoaders = () => {
  return (
    <Lottie
      animationData={animationData}
      autoplay
      loop
      style={{ height: '60px' }}
    />
  );
};

export default AFLoaders;
