import axios from 'axios';
import { membershipUrl } from './APIConstant';
import { useSearchParams } from 'react-router-dom';

const token = localStorage.getItem('aageToken');

// check for invalid token
export const handleInvalidToken = (status: any) => {
  if (status === 403) {
    window.location.href = '/token-expired';
  }
};

// check for several errors
export const handleInternalServerError = (response: any) => {
  if (response.status === 500) {
    window.location.href = '/something-went-wrong';
  }
};

// fetch user current membership
export const getUserMembership = async (token: string) => {
  try {
    const response: any = await axios.get(
      `${membershipUrl}/current_membership?token=${token}`
    );
    return response;
  } catch (err: any) {
    setTimeout(() => handleInvalidToken(err.response.status), 0);
    handleInternalServerError(err.response);
    return err.response;
  }
};

// fetch user pricing plans
export const getUserPricingPlans = async (token: string) => {
  try {
    const response: any = await axios.get(
      `${membershipUrl}/pricing_plans?token=${token}`
    );
    return response;
  } catch (err: any) {
    handleInvalidToken(err.response.status);
    handleInternalServerError(err.response);
    return err.response;
  }
};

// redirect to complete the payment
export const postPayment = async (
  token: string,
  planName: string,
  currency: string,
  paymentMethod: string
) => {
  try {
    const response: any = await axios.post(
      `${membershipUrl}/create_payment_link`,
      {
        token: token,
        plan_name: planName,
        currency: currency,
        payment_method: paymentMethod,
      }
    );
    return response;
  } catch (err: any) {
    handleInvalidToken(err.response.status);
    handleInternalServerError(err.response);
    return err.response;
  }
};
