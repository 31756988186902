import React, { useEffect } from 'react';
import { Strings } from '../../Constants/Strings';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout.tsx/Layout';

const FourthScreen = ({ err }: any) => {
  const { somethingWentWrongMsg } = Strings;
  useEffect(() => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    if (screenWidth < 550 && screenHeight < 1024) {
      setTimeout(() => {
        window.location.href = 'http://tg://t.me/auto_forwarder_bot';
      }, 30000);
    } else {
      setTimeout(() => {
        window.location.href = 'https://t.me/auto_forwarder_bot';
      }, 30000);
    }
  }, []);

  return (
    <>
      <Layout>
        <div>
          <h4 style={{ textAlign: 'center' }}>{somethingWentWrongMsg}</h4>
          <h4 style={{ textAlign: 'center' }}>
            {err ?? 'Something went wrong!'}
          </h4>

          {/* mobile-display */}
          <div className='btn-container mobile-display'>
            <a href='http://tg://t.me/auto_forwarder_bot' target='_self'>
              <Button variant='contained' className='login-code-btn'>
                Go to Auto Forwarder Bot
              </Button>
            </a>
          </div>

          {/* desktop-display */}
          <div className='btn-container desktop-display'>
            <a href='https://t.me/auto_forwarder_bot' target='_self'>
              <Button variant='contained' className='login-code-btn'>
                Go to Auto Forwarder Bot
              </Button>
            </a>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default FourthScreen;
