import React, { useEffect, useState } from 'react';
import Screens from './Screens/index';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import './App.scss';
import { baseUrl } from './Services/APIConstant';
import SomethingWentWrong from './Screens/SomethingWentWrong/SomethingWentWrong';
import UserCurrentPlan from './Screens/MainScreens/UserCurrentPlan';
import PricingPlans from './Screens/MainScreens/PricingPlans';
import PaymentSuccessful from './Screens/MainScreens/PaymentSuccessful';
import axios from 'axios';
import TokenExpired from './components/TokenExpired/TokenExpired';

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token: any = searchParams.get('token');
  // const token = `gAAAAABm7WgwCu-EzNMycpZe1Ah4cHL__qdZXb9EzdEzfw-Ac1zX661YdxNtOOQPCz3YOSjbiIFyumM1R3SlVigbqBiUYWwbSPKx7ofP_sqfdRllXJgAglU%3D`;
  localStorage.setItem('aageToken', token);

  // useEffect(() => {
  //   const getToken = async () => {
  //     try {
  //       // setIsLoading(true);
  //       const response = await axios.get(
  //         `${baseUrl}/token_code`
  //       );
  //       console.log('code response', response);
  //     } catch (err: any) {
  //       console.log('error', err.message);
  //       // setTokenExpired(true);
  //     } finally {
  //       // setIsLoading(false);
  //     }
  //   };
  //   getToken();
  // }, []);

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/authorize');
    }
  }, []);

  return (
    <div className='main-container'>
      <Routes>
        <Route path='/' element={<Screens />} />
        <Route path='/authorize' element={<Screens />} />
        <Route path='/membership' element={<UserCurrentPlan />} />
        <Route path='/pricing-plans' element={<PricingPlans />} />
        <Route path='/payment-successful' element={<PaymentSuccessful />} />
        <Route path='/token-expired' element={<TokenExpired />} />
        <Route path='/something-went-wrong' element={<SomethingWentWrong />} />
      </Routes>
    </div>
  );
}

export default App;
