import React, { useEffect, useState } from 'react';
import MainScreenLayout from '../../../components/MainScreenLayout/MainScreenLayout';
import './index.scss';
import AFButton from '../../../components/Button/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SERVICES } from '../../../Services';
import PricingPlans from '../PricingPlans';
import Loaders from '../../../components/Loaders/Loaders';

const Index = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>();
  const [currentPlan, setCurrentPlan] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [pricingPlans, setPricingPlans] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const token: any = searchParams.get('token');
  localStorage.setItem('aageToken', token);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPricingPlans = async () => {
      try {
        setIsLoading(true);
        const response: any = await SERVICES.getUserMembership(token);
        if (response.status === 200) {
          if (response.data.success === true) {
            setUserId(response.data.user_id);
            setCurrentPlan(response.data.plan_name);
            setStartDate(response.data.start_date);
            setEndDate(response.data.end_date);
          }
        }
        // else if (response.status === 500) {
        //   return <SomethingWentWrong err={response.data.message} />;
        // } else if (response.status === 429) {
        //   return <SomethingWentWrong err={response.data.message} />;
        // } else if (response.status === 403) {
        //   navigate('/token-expired');
        // }
      } catch (err: any) {
        console.log('error', err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPricingPlans();
  }, []);

  const handleRenew = () => {
    // navigate(`/pricing-plans?token=${token}`);
    setPricingPlans(true);
  };
  return (
    <>
      {!pricingPlans ? (
        <MainScreenLayout>
          {!isLoading ? (
            <>
              <div className='membership_details_container'>
                <h2>Current Membership Details</h2>
                <div className='details_wrapper'>
                  <div className='card_container'>
                    User ID: <p>{userId}</p>
                  </div>
                  <div className='card_container'>
                    Current Plan: <p>{currentPlan}</p>
                  </div>
                  <div className='card_container'>
                    Start Date: <p>{startDate}</p>
                  </div>
                  <div className='card_container'>
                    End Date: <p>{endDate}</p>
                  </div>
                </div>
              </div>
              <div className='renew-btn-wrapper'>
                <AFButton title='Renew/Upgrade Plan' onClick={handleRenew} />
              </div>
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: "100%"
              }}
            >
              <Loaders />
            </div>
          )}
        </MainScreenLayout>
      ) : (
        <PricingPlans />
      )}
    </>
  );
};

export default Index;
