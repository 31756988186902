import React, { useEffect, useState } from 'react';
import MainScreenLayout from '../../../components/MainScreenLayout/MainScreenLayout';
import './index.scss';
import Button from '../../../components/Button/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SERVICES } from '../../../Services';
import SomethingWentWrong from '../../SomethingWentWrong/SomethingWentWrong';
import Loaders from '../../../components/Loaders/Loaders';

const Index = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pricingData, setPricingData] = useState<any[]>([]);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token: any = searchParams.get('token');
  localStorage.setItem('aageToken', token);

  useEffect(() => {
    const fetchPricingPlans = async () => {
      try {
        setIsLoading(true);
        const response = await SERVICES.getUserPricingPlans(token);
        if (response.status === 200) {
          if (response.data.success === true) {
            setPricingData(response.data.plans);
          }
        }
      } catch (err: any) {
        console.log('error', err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPricingPlans();
  }, []);

  const handleStripePayment = async (
    planName: string,
    currency: string,
    paymentMethod: string
  ) => {
    try {
      setIsLoading(true);
      const response = await SERVICES.postPayment(
        token,
        planName,
        currency,
        paymentMethod
      );
      if (response.status === 200) {
        if (response.data.success === true) {
          window.location.replace(response.data.payment_link);
        }
      }
    } catch (err: any) {
      console.log('error', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <MainScreenLayout>
        <div className='feature-table-wrapper'>
          <h2>Pricing Plans</h2>
          {!isLoading ? (
            <div className='table-container'>
              <div className='pricing_wrapper'>
                <div className='plan_name'>Free</div>
                <div>Feature 1</div>
                <div>Free Forever</div>
                <div style={{ width: '160px' }}></div>
                <div style={{ width: '160px' }}></div>
              </div>
              {pricingData?.map((item, key) => {
                return (
                  <div key={key} className='pricing_wrapper'>
                    <div className='plan_name'>{item.plan_name} </div>
                    <div>{item.feature1}</div>
                    <div>
                      {item.currency === 'USD'
                        ? `$ ${item.price}/month`
                        : `₹ ${item.price}/month`}
                    </div>
                    <div style={{ width: 'auto', fontSize: '1 rem' }}>
                      {item.payment_methods?.map((btn: any, key: any) => (
                        <Button
                          key={key}
                          title={`Pay with ${btn}`}
                          onClick={() =>
                            handleStripePayment(
                              item.plan_name,
                              item.currency,
                              btn
                            )
                          }
                        />
                      ))}
                    </div>
                    {/* <div style={{ width: 'auto', fontSize: '1 rem' }}>
                      <Button
                        title={`Pay with ${item.payment_methods[0]}`}
                        onClick={() =>
                          handleStripePayment(
                            item.plan_name,
                            item.currency,
                            item.payment_methods[0]
                          )
                        }
                      />
                    </div> */}
                  </div>
                );
              })}
            </div>
          ) : (
            <Loaders />
          )}
        </div>
      </MainScreenLayout>
    </>
  );
};

export default Index;
